// App.js or Navbar.js (where your logout button is located)
import React, { useContext } from 'react';
import { AuthContext } from '../context/auth-context.js'; // Import your AuthContext
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const LogoutButton = () => {
  const { logout } = useContext(AuthContext); // Get logout function from context
  const navigate = useNavigate(); // Import useNavigate

  const handleLogout = () => {
    // Perform logout actions
    logout();

    // Redirect to the login page (or any other page)
    navigate('/login');
  };

  return (
    <button onClick={handleLogout}>
      Logout
    </button>
  );
};

export default LogoutButton;
