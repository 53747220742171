// Family.jsx
import React from 'react';
import { useForm, Controller} from 'react-hook-form';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Family.css';

const apiUrl = 'http://localhost:5000';

const Family = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    console.log('Form data submitted:', data);
    try {
      const response = await axios.post(`${apiUrl}/api/family`, data);
      console.log('Form submitted successfully:', response.data);
  
      // Navigate to the Income component on successful submission
      navigate('/income');
    } catch (error) {
      console.error('Error submitting form:', error);
      if (error.response) {
        // The request was made, but the server responded with an error
        console.error('Server responded with:', error.response.data);
      } else if (error.request) {
        // The request was made but no response was received
        console.error('No response received:', error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Error setting up the request:', error.message);
      }
    }
  };
  

  const goBack = () => {
    navigate('/details'); // Navigate back to the previous route (BasicDetails)
  };

  const generateOptions = (optionsArray, defaultOption = 'Select') => {
    const options = [{ value: '', label: defaultOption }];
    optionsArray.forEach((option) => {
      options.push({ value: option, label: option });
    });
    return options;
  };

  const occupations = [
    'Salaried Govt Employee',
    'Salaried Private Employee',
    'Business',
    'Daily Wager',
    'Driver',
    'Others',
  ];

  return (
    <div className="Family">
      <h2>Family Information Form</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <label>Total Family members</label>
        <Controller
          name="totalFamilyMembers"
          control={control}
          rules={{ required: 'This field is required' }}
          render={({ field }) => (
            <div>
              <select {...field}>
                {generateOptions(Array.from({ length: 12 }, (_, i) => i + 1)).map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              {errors.totalFamilyMembers && (
                <p className="error-message">* {errors.totalFamilyMembers.message}</p>
              )}
            </div>
          )}
        />
      </div>
      
      <div>
      <label>Total earning members</label>
      <Controller
        name="totalEarningMembers"
        control={control}
        rules={{ required: 'This field is required' }}
        render={({ field }) => (
          <div>
            <select {...field}>
              {generateOptions(Array.from({ length: 12 }, (_, i) => i + 1)).map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            {errors.totalEarningMembers && (
              <p className="error-message">* {errors.totalEarningMembers.message}</p>
            )}
          </div>
        )}
      />
      </div>
     
         <div>
         <label>Occupation</label>
        <Controller
          name="occupation"
          control={control}
          rules={{ required: 'This field is required' }}
          render={({ field }) => (
            <div>
              <select {...field}>
                {generateOptions(occupations).map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              {errors.occupation && <p className="error-message">* {errors.occupation.message}</p>}
            </div>
          )}
        />
         </div>
        
        <div>
        <label>Children</label>
        <Controller
          name="children"
          control={control}
          rules={{ required: 'This field is required' }}
          render={({ field }) => (
            <div>
              <select {...field}>
                {generateOptions(Array.from({ length: 12 }, (_, i) => i + 1)).map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              {errors.children && <p className="error-message">* {errors.children.message}</p>}
            </div>
          )}
        />
        </div>
       
        <div>
        <label>How many females</label>
        <Controller
          name="females"
          control={control}
          rules={{ required: 'This field is required' }}
          render={({ field }) => (
            <div>
              <select {...field}>
                {generateOptions(Array.from({ length: 13 }, (_, i) => i + 1)).map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              {errors.females && <p className="error-message">* {errors.females.message}</p>}
            </div>
          )}
        />
        </div>
        
       <div>
       <label>How many males</label>
        <Controller
          name="males"
          control={control}
          rules={{ required: 'This field is required' }}
          render={({ field }) => (
            <div>
              <select {...field}>
                {generateOptions(Array.from({ length: 14 }, (_, i) => i + 1)).map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              {errors.males && <p className="error-message">* {errors.males.message}</p>}
            </div>
          )}
        />

       </div>
        
        <button type="button" onClick={goBack} className="back-button">
          Previous
        </button>

        <button type="submit" className="submit-button">
          Save & Continue
        </button>
      </form>
    </div>
  );
};

export default Family;
