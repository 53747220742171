import React from 'react';
import './Home.css';
import surveyImage from '../Assests/AZKA Solutions.png'; // Replace with the actual path or use a placeholder image URL

const Home = () => {
  // Sample placeholder image URL
  const placeholderImageUrl = surveyImage;

  return (
    <div className='home'>
      <div className='survey-image-container'>
      <h1>Welcome to AZKA Survey</h1>
        <img src={placeholderImageUrl} alt='Survey' className='survey-image' />
        <p className='survey-image-text'> AZKA Survey is a cutting-edge platform dedicated to conducting precise and reliable government surveys.
        Our mission is to gather valuable insights and data that contribute to informed decision-making at the governmental level.</p>
      </div>
      <div className='content'>
        <p>
          Whether it's population demographics, public opinion, or specific policy feedback, AZKA Survey is your go-to
          solution for accurate and efficient surveying processes.
        </p>
        <p>
          Join us in shaping the future by participating in our surveys and making your voice count. Your input matters!
        </p>
      </div>
    </div>
  );
};

export default Home;
