// BasicDetails.jsx
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './BasicDetails.css';

const apiUrl = 'http://localhost:5000';

const BasicDetails = () => {
  const navigate = useNavigate();
  const {
    handleSubmit,
    register,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const statesList = [
    'Andhra Pradesh',
    'Arunachal Pradesh',
    'Assam',
    'Bihar',
    'Chhattisgarh',
    'Goa',
    'Gujarat',
    'Haryana',
    'Himachal Pradesh',
    'Jharkhand',
    'Karnataka',
    'Kerala',
    'Madhya Pradesh',
    'Maharashtra',
    'Manipur',
    'Meghalaya',
    'Mizoram',
    'Nagaland',
    'Odisha',
    'Punjab',
    'Rajasthan',
    'Sikkim',
    'Tamil Nadu',
    'Telangana',
    'Tripura',
    'Uttar Pradesh',
    'Uttarakhand',
    'West Bengal',
  ];

  useEffect(() => {
    setValue('religion', ''); // Set an empty string to show the default "Select" option
    setValue('gender', ''); // Set an empty string to show the default "Select" option
  }, [setValue]);

  const onSubmit = async (data) => {
    console.log('Form data submitted:', data);
    try {
      const response = await axios.post(`${apiUrl}/api/details/`, data);
      console.log('Form submitted successfully:', response.data);
      reset();
      navigate('/family');
    } catch (error) {
      console.error('Error submitting form:', error.response.data);
    }
  };

  return (
    <div className="BasicDetails">
      <h2>Basic Details Form</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <label htmlFor="name">Name</label>
        <input type="text" id="name" {...register('name', { required: 'Name is required' })} />
        {errors.name && <p className="error-message">{errors.name.message}</p>}

        <label htmlFor="address">Address</label>
        <input type="text" id="address" {...register('address', { required: 'Address is required' })} />
        {errors.address && <p className="error-message">{errors.address.message}</p>}

       {/* Phone */}
       <label htmlFor="phone">Phone</label>
       <input
         type="text"
         id="phone"
         {...register('phone', { required: 'Phone is required' })}
       />
       {errors.phone && <p className="error-message">{errors.phone.message}</p>}

       {/* Constituency MLA */}
       <label htmlFor="constituencyMLA">Constituency MLA</label>
       <input
         type="text"
         id="constituencyMLA"
         {...register('constituencyMLA', { required: 'Constituency MLA is required' })}
       />
       {errors.constituencyMLA && <p className="error-message">{errors.constituencyMLA.message}</p>}

       {/* Constituency MP */}
       <label htmlFor="constituencyMP">Constituency MP</label>
       <input
         type="text"
         id="constituencyMP"
         {...register('constituencyMP', { required: 'Constituency MP is required' })}
       />
       {errors.constituencyMP && <p className="error-message">{errors.constituencyMP.message}</p>}

       {/* Age */}
       <label htmlFor="age">Age</label>
       <input
         type="number"
         id="age"
         {...register('age', { required: 'Age is required' })}
       />
       {errors.age && <p className="error-message">{errors.age.message}</p>}

       {/* Gender */}
       <label htmlFor="gender">Gender</label>
       <select
         id="gender"
         {...register('gender', { required: 'Gender is required' })}
         defaultValue="" // Use defaultValue to set the default value
       >
         <option value="" disabled>
           Select Gender
         </option>
         <option value="Male">Male</option>
         <option value="Female">Female</option>
         <option value="Transgender">Transgender</option>
       </select>
       {errors.gender && <p className="error-message">{errors.gender.message}</p>}


       {/* State */}
       <label htmlFor="state">State</label>
       <label htmlFor="state">State</label>
       <select
         id="state"
         {...register('state', { required: 'State is required' })}
         defaultValue="" // Set an empty string as the default value
       >
         <option value="" disabled>
           Select State
         </option>
         {statesList.map((state) => (
           <option key={state} value={state}>
             {state}
           </option>
         ))}
       </select>
       {errors.state && <p className="error-message">{errors.state.message}</p>}
       

       
             {/* District */}
             <label htmlFor="district">District</label>
             <input
               type="text"
               id="district"
               {...register('district', { required: 'District is required' })}
             />
             {errors.district && <p className="error-message">{errors.district.message}</p>}
     
             {/* Revenue Division */}
             <label htmlFor="revenueDivision">Revenue Division</label>
             <input
               type="text"
               id="revenueDivision"
               {...register('revenueDivision', { required: 'Revenue Division is required' })}
             />
             {errors.revenueDivision && <p className="error-message">{errors.revenueDivision.message}</p>}
     
             {/* Mandal */}
             <label htmlFor="mandal">Mandal</label>
             <input
               type="text"
               id="mandal"
               {...register('mandal', { required: 'Mandal is required' })}
             />
             {errors.mandal && <p className="error-message">{errors.mandal.message}</p>}
     
             {/* Religion */}
             <label htmlFor="religion">Religion</label>
             <select
               id="religion"
               {...register('religion', { required: 'Religion is required' })}
             >
               <option value="" disabled>
                 Select Religion
               </option>
               <option value="Hindu">Hindu</option>
               <option value="Muslim">Muslim</option>
               <option value="Sikh">Sikh</option>
               <option value="Christian">Christian</option>
               <option value="Others">Others</option>
             </select>
             {errors.religion && <p className="error-message">{errors.religion.message}</p>}
     
             {/* Caste */}
             <label htmlFor="caste">Caste</label>
             <input
               type="text"
               id="caste"
               {...register('caste', { required: 'Caste is required' })}
             />
             {errors.caste && <p className="error-message">{errors.caste.message}</p>}
     
             {/* Ward */}
             <label htmlFor="ward">Ward</label>
             <input
               type="number"
               id="ward"
               {...register('ward', { required: 'Ward is required', min: 1, max: 50 })}
             />
             {errors.ward && <p className="error-message">{errors.ward.message}</p>}

        <button type="submit" className="submit-button">
          Save & Continue
        </button>
      </form>
    </div>
  );
};

export default BasicDetails;
