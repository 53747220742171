import React, { useState, useCallback } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom'; // Add useNavigate

import AdminRecord from './pages/AdminRecord';
import BasicDetails from './pages/BasicDetails';
import Family from './pages/Family';
import Government from './pages/Government';
import Income from './pages/Income';
import Home from './pages/Home';
import Auth from './pages/Auth';
import LogoutButton from './pages/Logout';
import { Navbar } from './components/Navbar';
import { AuthContext } from './context/auth-context';

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate(); // useNavigate hook

  const login = useCallback(() => {
    setIsLoggedIn(true);
    navigate('/', { replace: true }); // Redirect to home on login
  }, [navigate]);

  const logout = useCallback(() => {
    setIsLoggedIn(false);
  }, []);

  return (
    <AuthContext.Provider value={{ isLoggedIn: isLoggedIn, login: login, logout: logout }}>
      <>
        {/* Render Navbar for all routes */}
        <Navbar />

        <Routes>
          {/* Common routes */}
          <Route path="/" element={<Home />} />

          {/* Your other routes */}
          {isLoggedIn && (
            <>
              <Route path="/admin" element={<AdminRecord />} />
              <Route path="/details" element={<BasicDetails />} />
              <Route path="/family" element={<Family />} />
              <Route path="/income" element={<Income />} />
              <Route path="/government" element={<Government />} />
              <Route path="/logout" element={<LogoutButton />} />
            </>
          )}

          {/* Auth route */}
          <Route path="/auth" element={<Auth />} />
        </Routes>
      </>
    </AuthContext.Provider>
  );
};

export default App;
