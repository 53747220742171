// Government.jsx
import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Government.css'; // Import the CSS file

const apiUrl = 'http://localhost:5000';

const Government = () => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    console.log('Form data submitted:', data);
    try {
      const response = await axios.post(`${apiUrl}/api/government`, data);
      console.log('Form submitted successfully:', response.data);

       // Reset the form after successful submission
       reset();
       navigate('/admin');
    } catch (error) {
      console.error('Error submitting form:', error.response.data);
    }
  };

  const goBack = () => {
    navigate('/income'); // Navigate back to the previous route
  };

  const generateOptions = (optionsArray, defaultOption = 'Select') => {
    const options = [{ value: '', label: defaultOption }];
    optionsArray.forEach((option) => {
      options.push({ value: option, label: option });
    });
    return options;
  };

  const ratingOptions = ['excellent', 'better', 'good', 'average', 'worse', 'worst'];
  const yesNoOptions = ['Yes', 'No', 'Not decided'];

  return (
    <div className="Government">
      <h2>Government Information Form</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* Street Roads */}
        <div>
          <label>Street Roads</label>
          <Controller
            name="streetRoads"
            control={control}
            rules={{ required: 'This field is required' }}
            render={({ field }) => (
              <div>
                <select {...field}>
                  {generateOptions(ratingOptions).map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                {errors.streetRoads && (
                  <p className="error-message">* {errors.streetRoads.message}</p>
                )}
              </div>
            )}
          />
        </div>

        {/* District Connecting Roads */}
        <div>
          <label>District Connecting Roads</label>
          <Controller
            name="districtConnectingRoads"
            control={control}
            rules={{ required: 'This field is required' }}
            render={({ field }) => (
              <div>
                <select {...field}>
                  {generateOptions(ratingOptions).map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                {errors.districtConnectingRoads && (
                  <p className="error-message">* {errors.districtConnectingRoads.message}</p>
                )}
              </div>
            )}
          />
        </div>

        {/* Government Hospitals facilities */}
        <div>
          <label>Government Hospitals facilities</label>
          <Controller
            name="governmentHospitalsFacilities"
            control={control}
            rules={{ required: 'This field is required' }}
            render={({ field }) => (
              <div>
                <select {...field}>
                  {generateOptions(ratingOptions).map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                {errors.governmentHospitalsFacilities && (
                  <p className="error-message">* {errors.governmentHospitalsFacilities.message}</p>
                )}
              </div>
            )}
          />
        </div>

        {/* Government facilities and availability */}
        <div>
          <label>Government facilities and availability</label>
          <Controller
            name="governmentFacilitiesAvailability"
            control={control}
            rules={{ required: 'This field is required' }}
            render={({ field }) => (
              <div>
                <select {...field}>
                  {generateOptions(ratingOptions).map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                {errors.governmentFacilitiesAvailability && (
                  <p className="error-message">* {errors.governmentFacilitiesAvailability.message}</p>
                )}
              </div>
            )}
          />
        </div>

        {/* Town/Village Roads */}
        <div>
        <label>Town/Village Roads</label>
        <Controller
          name="townVillageRoads"
          control={control}
          rules={{ required: 'This field is required' }}
          render={({ field }) => (
            <div>
              <select {...field}>
                {generateOptions(ratingOptions).map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              {errors.townVillageRoads && (
                <p className="error-message">* {errors.townVillageRoads.message}</p>
              )}
            </div>
          )}
        />
      </div>

      {/* Transportation */}
      <div>
        <label>Transportation</label>
        <Controller
          name="transportation"
          control={control}
          rules={{ required: 'This field is required' }}
          render={({ field }) => (
            <div>
              <select {...field}>
                {generateOptions(ratingOptions).map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              {errors.transportation && (
                <p className="error-message">* {errors.transportation.message}</p>
              )}
            </div>
          )}
        />
      </div>

      {/* Government School facilities */}
      <div>
        <label>Government School facilities</label>
        <Controller
          name="governmentSchoolFacilities"
          control={control}
          rules={{ required: 'This field is required' }}
          render={({ field }) => (
            <div>
              <select {...field}>
                {generateOptions(ratingOptions).map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              {errors.governmentSchoolFacilities && (
                <p className="error-message">* {errors.governmentSchoolFacilities.message}</p>
              )}
            </div>
          )}
        />
      </div>

      {/* Will you vote for the same Government */}
      <div>
        <label>Will you vote for the same Government</label>
        <Controller
          name="willVoteForSameGovernment"
          control={control}
          rules={{ required: 'This field is required' }}
          render={({ field }) => (
            <div>
              <select {...field}>
                {generateOptions(yesNoOptions).map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              {errors.willVoteForSameGovernment && (
                <p className="error-message">* {errors.willVoteForSameGovernment.message}</p>
              )}
            </div>
          )}
        />
      </div>

        {/* Add similar structures for other fields */}
        {/* ... */}

        <button type="button" onClick={goBack} className="back-button">
          Previous
        </button>

        <button type="submit" className="submit-button">
          Submit
        </button>
      </form>
    </div>
   
  );
};

export default Government;
