// Income.jsx
import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Income.css'; // Import the CSS file

const apiUrl = 'http://localhost:5000';

const Income = () => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    console.log('Form data submitted:', data);
    try {
      const response = await axios.post(`${apiUrl}/api/income`, data);
      console.log('Form submitted successfully:', response.data);

      // Reset the form after successful submission
      reset();
      navigate('/government');
    } catch (error) {
      console.error('Error submitting form:', error.response.data);
    }
  };

  const goBack = () => {
    navigate('/family'); // Navigate back to the home route
  };

  const generateOptions = (optionsArray, defaultOption = 'Select') => {
    const options = [{ value: '', label: defaultOption }];
    optionsArray.forEach((option) => {
      options.push({ value: option, label: option });
    });
    return options;
  };

  const earnersOptions = Array.from({ length: 12 }, (_, index) => (index + 1).toString());
  const savingOptions = ['None', 'Up to 10000', '10000-50000', '50000-100000', 'Above 1 Lakh'];
  const debtOptions = ['None', 'Up to 10000', '10000-50000', '50000-100000', 'Above 1 Lakh'];
  const interestRatesOptions = Array.from({ length: 50 }, (_, index) => (index + 1).toString());
  const sourceOfDebtOptions = ['Local Pawn', 'Bank Personal Loan', 'Bank Home Loan', 'Govt Schemes', 'Others'];

  return (
    <div className="Income">
      <h2>Income Information Form</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* How many earners */}
        <div>
          <label>How many earners</label>
          <Controller
            name="earnersCount"
            control={control}
            rules={{ required: 'This field is required' }}
            render={({ field }) => (
              <div>
                <select {...field}>
                  {generateOptions(earnersOptions).map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                {errors.earnersCount && (
                  <p className="error-message">* {errors.earnersCount.message}</p>
                )}
              </div>
            )}
          />
        </div>

        {/* Saving per month */}
        <div>
          <label>Saving per month</label>
          <Controller
            name="savingPerMonth"
            control={control}
            rules={{ required: 'This field is required' }}
            render={({ field }) => (
              <div>
                <select {...field}>
                  {generateOptions(savingOptions).map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                {errors.savingPerMonth && (
                  <p className="error-message">* {errors.savingPerMonth.message}</p>
                )}
              </div>
            )}
          />
        </div>

        {/* How much debt */}
        <div>
          <label>How much debt</label>
          <Controller
            name="debtAmount"
            control={control}
            rules={{ required: 'This field is required' }}
            render={({ field }) => (
              <div>
                <select {...field}>
                  {generateOptions(debtOptions).map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                {errors.debtAmount && (
                  <p className="error-message">* {errors.debtAmount.message}</p>
                )}
              </div>
            )}
          />
        </div>

        {/* Interest Rates */}
        <div>
          <label>Interest Rates</label>
          <Controller
            name="interestRates"
            control={control}
            rules={{ required: 'This field is required' }}
            render={({ field }) => (
              <div>
                <select {...field}>
                  {generateOptions(interestRatesOptions).map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                {errors.interestRates && (
                  <p className="error-message">* {errors.interestRates.message}</p>
                )}
              </div>
            )}
          />
        </div>

        {/* Source of debt */}
        <div>
          <label>Source of debt</label>
          <Controller
            name="sourceOfDebt"
            control={control}
            rules={{ required: 'This field is required' }}
            render={({ field }) => (
              <div>
                <select {...field}>
                  {generateOptions(sourceOfDebtOptions).map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                {errors.sourceOfDebt && (
                  <p className="error-message">* {errors.sourceOfDebt.message}</p>
                )}
              </div>
            )}
          />
        </div>

        {/* Add similar structures for other fields */}
        {/* ... */}

        <button type="button" onClick={goBack} className="back-button">
          Previous
        </button>

        <button type="submit" className="submit-button">
        Save & Continue
        </button>
      </form>
    </div>
  );
};

export default Income;
