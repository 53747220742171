import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './AdminRecord.css';

const apiUrl = 'http://localhost:5000/api/records';

const AdminRecord = () => {
  const [records, setRecords] = useState([]);

  const fetchRecords = async () => {
    try {
      const response = await axios.get(apiUrl);
      setRecords(response.data || []);
    } catch (error) {
      console.error('Error fetching records:', error.response?.data || error.message);
    }
  };

  useEffect(() => {
    // Fetch records when the component mounts
    fetchRecords();
  }, []); // Empty dependency array ensures it only runs once

  const downloadData = () => {
    // Check if records exist before proceeding with the download
    if (records.length === 0) {
      console.warn('No records to download.');
      return;
    }

    // Create a Blob containing the data in JSON format
    const jsonData = JSON.stringify(records, null, 2); // null, 2 for pretty formatting
    const blob = new Blob([jsonData], { type: 'application/json' });

    // Create a link element and trigger a download
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'records.json';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="AdminRecord">
      <h2>Admin Record Details</h2>

      {/* Fetch Data Button */}
      <button onClick={fetchRecords}>Fetch Data</button>

      {/* Download Data Button */}
      <button onClick={downloadData}>Download Data</button>

      {/* Records Table */}
      <table className="records-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Address</th>
            <th>Phone</th>
            <th>Constituency MLA</th>
            <th>Constituency MP</th>
            <th>Age</th>
            <th>Gender</th>
            <th>State</th>
            <th>District</th>
            <th>Revenue Division</th>
            <th>Mandal</th>
            <th>Religion</th>
            <th>Caste</th>
            <th>Ward</th>
            {/* Add additional columns based on your data structure */}
          </tr>
        </thead>
        <tbody>
          {Array.isArray(records) &&
            records.map((record) => (
              <tr key={record.id}>
                <td>{record.id}</td>
                <td>{record.name}</td>
                <td>{record.address}</td>
                <td>{record.phone}</td>
                <td>{record.constituencyMLA}</td>
                <td>{record.constituencyMP}</td>
                <td>{record.age}</td>
                <td>{record.gender}</td>
                <td>{record.state}</td>
                <td>{record.district}</td>
                <td>{record.revenueDivision}</td>
                <td>{record.mandal}</td>
                <td>{record.religion}</td>
                <td>{record.caste}</td>
                <td>{record.ward}</td>
                {/* Add additional cells based on your data structure */}
              </tr>
            ))}
        </tbody>
      </table>

      {/* Additional Tables for Government, Income, and Family Details */}
      {Array.isArray(records) &&
        records.map((record) => (
          <div key={record.id}>
            <h3>{`${record.name}'s Details`}</h3>

            {/* Government Details Table */}
            <table className="details-table">
              <caption>Government Details</caption>
              <thead>
                <tr>
                  <th>Government ID</th>
                  <th>Position</th>
                  {/* Add additional columns based on government details */}
                </tr>
              </thead>
              <tbody>
                {record.governmentDetails && (
                  <tr>
                    <td>{record.governmentDetails.id}</td>
                    <td>{record.governmentDetails.position}</td>
                    {/* Add additional cells based on government details */}
                  </tr>
                )}
              </tbody>
            </table>

            {/* Income Details Table */}
            <table className="details-table">
              <caption>Income Details</caption>
              <thead>
                <tr>
                  <th>Income ID</th>
                  <th>Amount</th>
                  {/* Add additional columns based on income details */}
                </tr>
              </thead>
              <tbody>
                {record.incomeDetails && (
                  <tr>
                    <td>{record.incomeDetails.id}</td>
                    <td>{record.incomeDetails.amount}</td>
                    {/* Add additional cells based on income details */}
                  </tr>
                )}
              </tbody>
            </table>

            {/* Family Details Table */}
            <table className="details-table">
              <caption>Family Details</caption>
              <thead>
                <tr>
                  <th>Family ID</th>
                  <th>Members</th>
                  {/* Add additional columns based on family details */}
                </tr>
              </thead>
              <tbody>
                {record.familyDetails && (
                  <tr>
                    <td>{record.familyDetails.id}</td>
                    <td>{record.familyDetails.members}</td>
                    {/* Add additional cells based on family details */}
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        ))}
    </div>
  );
};

export default AdminRecord;
