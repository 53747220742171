import React, { useContext} from 'react'
import { Link } from 'react-router-dom'
import { AuthContext } from '../context/auth-context';
import'./Navbar.css';
import LogoutButton from '../pages/Logout';

export const Navbar = () => {
  const auth = useContext(AuthContext);
  return (
    <div className='nav-bar'>
    <h2>AZKA Survey</h2>
    <nav className='nav-in'>
      <ul>
      <li>
          <Link to="/">Home</Link>
        </li>
        {auth.isLoggedIn && (
        <li>
          <Link to="/details">Basic Details</Link>
        </li>
        )}
        {auth.isLoggedIn && (
        <li>
          <Link to="/family">Family Information</Link>
        </li>
        )}
        {auth.isLoggedIn && (
        <li>
          <Link to="/income">Income Information</Link>
        </li>
        )}
        {auth.isLoggedIn && (
        <li>
          <Link to="/government">Government Information</Link>
        </li>
        )}
        {auth.isLoggedIn && (
        <li>
          <Link to="/admin">Admin Records</Link>
        </li>
        )}
        {!auth.isLoggedIn && (
        <li>
          <Link to="/auth">Authentication</Link>
        </li>
        )} 
        {auth.isLoggedIn && (
          <LogoutButton/>
          )} 
      </ul>
    </nav>
  </div>
  )
}
